import { GetServerSideProps, NextPage } from "next";
import { useEffect } from "react";
import { createOAuthState, setOAuthState } from "../../utils/cookie";
import { client } from "../../utils/graphql-client";
import { publicRuntimeConfig } from "../../utils/next-helpers/runtime-config";
import {
  GetAuthorizationUriVariables,
  GetAuthorizationUriDocument,
} from "./graphql/get-authorization-uri-query";

type Props = Readonly<{
  state: string;
  authorizationURI: string;
}>;

const Auth: NextPage<Props> = (props) => {
  useEffect(() => {
    // fetch で getServerSideProps にリクエストされる場合でも
    // 問題なくクッキーに保存できるようにするために
    // クライアントサイドでクッキーに保存する
    setOAuthState(props.state);
    window.location.assign(props.authorizationURI);
  }, [props]);

  return null;
};

export const getServerSideProps: GetServerSideProps<Props> = async (context) => {
  const state = createOAuthState();
  const redirectUrl = publicRuntimeConfig.ensureString("NEXT_PUBLIC_AUTH_REDIRECT_URL");

  const variables: GetAuthorizationUriVariables = {
    email: null,
    redirect_uri: redirectUrl,
    state,
    code_challenge: null,
    code_challenge_method: null,
  };
  if (typeof context.query.email === "string") {
    variables.email = context.query.email;
  }

  const { data, errors } = await client(context, GetAuthorizationUriDocument, { variables });

  if (errors && errors.length > 0) {
    throw new Error(
      `Failed to get the authentication URL. ${errors.map((e) => e.message).join(" ")}`
    );
  }

  return {
    props: {
      state,
      authorizationURI: data.getAuthorizationURI,
    },
  };
};

export default Auth;
